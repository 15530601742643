<template>
  <v-card height="100%">
    <v-card-title> Alerts </v-card-title>
    <v-card-text>
      <v-list three-line class="mx-0 px-0">
        <template v-for="message in messages">
          <v-list-item :key="message.id">
            <v-list-item-avatar>
              <v-icon :color="message.color">mdi-alert</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Time: {{ getFormattedTimestamp(message.timestamp) }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ message.text }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "EventsPanel",
})
export default class EventsPanel extends Vue {
  @Prop({ required: true })
  messages!: any[];

  getFormattedTimestamp(timestamp: string) {
    const date = new Date(timestamp);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes)
    );
  }
}
</script>

<style scoped></style>
