<template>
  <v-card>
    <v-card-title> Control panel </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-btn @click="startGame" color="success" block>
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn @click="pauseGame" color="warning" block>
            <v-icon>mdi-pause</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn @click="stopGame" color="error" block>
            <v-icon>mdi-stop</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <v-row>
        <v-col cols="12" md="6">
          <v-btn block @click="addParasite" color="success">Add parasite</v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn block @click="removeParasite" color="error">
            Remove parasite
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="contaminationReduction"
            type="number"
            label="Contamination reduction"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn block @click="reduceContamination" color="error">
            Reduce contamination
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="message.color"
            :items="severityOptions"
            label="Severity"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="message.text"
            label="Text"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn @click="sendMessage" block color="success">Send message</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import gameApi from "@/api/gameApi";
import { MessageDto } from "@/models/messageDto";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ControlPanel",
})
export default class ControlPanel extends Vue {
  contaminationReduction = 0;
  message = new MessageDto("", "error");
  severityOptions = ["error", "warning", "info", "success"];

  async startGame() {
    await gameApi.startGame();
    this.$emit("refresh");
  }
  async pauseGame() {
    await gameApi.pauseGame();
    this.$emit("refresh");
  }
  async stopGame() {
    await gameApi.stopGame();
    this.$emit("refresh");
  }

  async addParasite() {
    await gameApi.addParasite();
    this.$emit("refresh");
  }

  async removeParasite() {
    await gameApi.removeParasite();
    this.$emit("refresh");
  }

  async reduceContamination() {
    gameApi.reduceContamination(this.contaminationReduction);
    this.$emit("refresh");
  }

  async sendMessage() {
    await gameApi.sendMessage(this.message);
    this.$emit("refresh");
    this.message = new MessageDto("", "", "error");
  }
}
</script>

<style scoped></style>
