import { MessageDto } from "@/models/messageDto";
import api from "./api";

export default {
  baseUrl: "Game/",

  async getAdminState() {
    return await api.get(this.baseUrl + "getAdminState");
  },

  async startGame() {
    return await api.post(this.baseUrl + "startGame");
  },

  async pauseGame() {
    return await api.post(this.baseUrl + "pauseGame");
  },

  async stopGame() {
    return await api.post(this.baseUrl + "stopGame");
  },

  async addParasite() {
    return await api.post(this.baseUrl + "addParasite");
  },

  async removeParasite() {
    return await api.post(this.baseUrl + "removeParasite");
  },

  async reduceContamination(reduction: number) {
    return await api.post(this.baseUrl + "reduceContaminationLevel", reduction);
  },

  async sendMessage(message: MessageDto) {
    return await api.post(this.baseUrl + "sendMessage", message);
  },
};
