<template>
  <v-container grid-list-md>
    <v-row>
      <v-col cols="12" md="6">
        <overview-card :state="state" @refresh="loadState()"></overview-card>
      </v-col>
      <v-col cols="12" md="6">
        <control-panel @refresh="loadState()"></control-panel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import api from "@/api/api";
import gameApi from "@/api/gameApi";
import OverviewCard from "@/components/admin/OverviewCard.vue";
import ControlPanel from "@/components/admin/ControlPanel.vue";

@Component({
  name: "AdminView",
  components: {
    OverviewCard,
    ControlPanel,
  },
})
export default class AdminView extends Vue {
  state = {};

  mounted() {
    this.loadState();
  }

  async loadState() {
    var state = await gameApi.getAdminState();
    console.log("🚀 ~ AdminView ~ loadState ~ state:", state);
    this.state = state;
  }
}
</script>

<style scoped></style>
