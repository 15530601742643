import axios from "axios";

export default {
  async get(url: string) {
    const response = await axios.get(url);
    if (response.data) {
      console.log("🚀 ~ get ~ response.data:", response.data);
      return response.data;
    }
  },

  async post(url: string, data?: any) {
    const response = await axios.post(url, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.data) {
      console.log("🚀 ~ post ~ response.data:", response.data);
      return response.data;
    }
  },
};
