export class MessageDto {
  public text: string;
  public color: string;
  public timestamp: Date;

  constructor(text: string, color: string, timestamp = "") {
    this.text = text;
    this.color = color;
    this.timestamp = new Date(timestamp);
  }
}
