import { render, staticRenderFns } from "./OverviewCard.vue?vue&type=template&id=a10b5dec&scoped=true"
import script from "./OverviewCard.vue?vue&type=script&lang=ts"
export * from "./OverviewCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a10b5dec",
  null
  
)

export default component.exports