<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <scan-progress-panel
          :value="tickCounter / 6"
          :showInstallButton="showInstallButton"
          @init-install="installApp()"
        ></scan-progress-panel>
      </v-flex>
      <v-flex xs4>
        <contamination-level-panel
          :contaminationLevel="contaminationLevel"
        ></contamination-level-panel>
      </v-flex>
      <v-flex xs4>
        <contamination-rate-panel
          :contaminationRate="contaminationRate"
          :cRateHistory="cRateHistory"
          :contaminationLevel="contaminationLevel"
        ></contamination-rate-panel>
      </v-flex>
      <v-flex xs4>
        <events-panel :messages="messages"></events-panel>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ScanProgressPanel from "@/components/scan-progress-panel.vue";
import ContaminationLevelPanel from "@/components/contamination-level-panel.vue";
import ContaminationRatePanel from "@/components/contamination-rate-panel.vue";
import EventsPanel from "@/components/events-panel.vue";
import api from "@/api/api";

@Component({
  name: "HomeView",
  components: {
    ScanProgressPanel,
    ContaminationLevelPanel,
    ContaminationRatePanel,
    EventsPanel,
  },
})
export default class HomeView extends Vue {
  selectedTab = 0;
  slideInterval: number | null = null;
  tickCounter = 0;

  contaminationLevel = 0;
  contaminationRate = 0;
  parasitesCount = 0;
  durationInMimutes = 0;
  cRateHistory: number[] = [];
  messages: any[] = [];

  diminishingImpact(x: number) {
    const A = 7; // The difference between y(1) and the asymptote y(10)
    const k = 0.5; // Adjust this for how quickly the curve decays
    const C = 1; // Asymptote, y(10)

    return A * Math.exp(-k * (x - 1)) + C;
  }

  calculateIncrement(y: number) {
    const totalMinutes = y * 60;
    const incrementPerMinute = 100 / totalMinutes;
    return incrementPerMinute;
  }

  mounted() {
    window.addEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
    this.slideInterval = setInterval(this.tick, 100);
    this.getClientState();
  }

  async getClientState() {
    var state = await api.get("Game/getClientState");
    console.log("🚀 ~ HomeView ~ getClientState ~ state:", state);
    this.contaminationLevel = state.contaminationLevel;
    this.contaminationRate = state.lastContaminationRate;
    this.messages = state.messages;
    this.cRateHistory = state.contaminationRateHistory;
  }

  async tick() {
    this.tickCounter++;

    // update every minute (value should be 600!)
    if (this.tickCounter >= 600) {
      await this.getClientState();

      // reset tickCounter
      this.tickCounter = 0;
    }
  }

  beforeDestroy() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
    window.removeEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
  }

  //pwa install
  // Define properties for TypeScript
  private deferredPrompt: any = null;
  public showInstallButton = false;

  handleBeforeInstallPrompt(event: any) {
    // Prevent the default prompt
    event.preventDefault();
    // Store the event for triggering the prompt later
    this.deferredPrompt = event;
    // Show the install button
    this.showInstallButton = true;
  }

  // Trigger the installation prompt
  private async installApp() {
    if (this.deferredPrompt) {
      // Show the prompt to the user
      this.deferredPrompt.prompt();

      // Wait for the user's choice
      const choiceResult = await this.deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }

      // Clear the deferred prompt so it can’t be used again
      this.deferredPrompt = null;
      this.showInstallButton = false;
    }
  }
}
</script>

<style scoped></style>
