<template>
  <v-card height="100%">
    <v-card-title> Contamination level </v-card-title>
    <v-card-text>
      <div class="center">
        <v-progress-circular
          :value="contaminationLevel"
          :color="color"
          size="256"
          width="32"
          rotate="90"
        >
          <h1>{{ contaminationLevel.toFixed(2) }}%</h1>
        </v-progress-circular>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { getProgressColor } from "@/utils/utils";

@Component({
  name: "ContaminationLevelPanel",
  components: {},
})
export default class ContaminationLevelPanel extends Vue {
  @Prop({ required: true })
  contaminationLevel!: number;

  get color() {
    return getProgressColor(this.contaminationLevel);
  }
}
</script>

<style scoped></style>
