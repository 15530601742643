<template>
  <v-card>
    <v-card-title> Overview </v-card-title>
    <v-card-text>
      <v-text-field v-model="state.runState" label="Game state" outlined dense>
      </v-text-field>
      <v-text-field
        v-model="state.contaminationLevel"
        label="Contamination level"
        outlined
        dense
      >
      </v-text-field>
      <v-text-field
        v-model="state.currentContaminationRate"
        label="Current contamination rate"
        outlined
        dense
      >
      </v-text-field>
      <v-text-field
        v-model="state.parasiteCount"
        label="Parasite count"
        outlined
        dense
      >
      </v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="onRefreshClicked">Refresh</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/api";

@Component({
  name: "OverviewCard",
})
export default class OverviewCard extends Vue {
  @Prop({ required: true })
  state: any;

  onRefreshClicked() {
    this.$emit("refresh");
  }
}
</script>

<style scoped></style>
