<template>
  <v-card height="100%">
    <v-card-title> Contamination rate </v-card-title>
    <v-card-text>
      <div class="center mt-12">
        <h1 class="blue--text">+{{ contaminationRate.toFixed(4) }}/min</h1>
      </div>
      <div class="center" v-if="contaminationRate > 0">
        <span class="red--text" style="font-size: smaller"
          >Estimated time to 100%: {{ estimatedTime.toFixed(0) }} minutes</span
        >
      </div>
      <v-sparkline
        class="mt-12"
        :value="cRateHistory"
        :gradient="gradient"
        auto-draw
      ></v-sparkline>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ContaminationRatePanel",
})
export default class ContaminationRatePanel extends Vue {
  @Prop({ required: true })
  contaminationRate!: number;

  @Prop({ required: true })
  cRateHistory!: number[];

  @Prop({ required: true })
  contaminationLevel!: number;

  gradient = ["#F44336", "#FF9800", "#4CAF50"];

  get estimatedTime() {
    return (100 - this.contaminationLevel) / this.contaminationRate;
  }
}
</script>

<style scoped></style>
