export function getProgressColor(value: number) {
  if (value < 25) {
    return "success";
  }
  if (value < 50) {
    return "warning";
  } else {
    return "error";
  }
}
